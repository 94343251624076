import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({ siteTitle, fullwidth }) => (
  <header
    style={{
      background: `#fff`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `0.2rem 1.0875rem`,
      }}
    >
      <h1
        style={
          fullwidth
            ? { margin: 0, fontSize: "0.75rem", textAlign: "center" }
            : { margin: 0, fontSize: "1rem" }
        }
      >
        <Link
          to="/"
          style={{
            color: `#000`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  fullwidth: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: ``,
  fullwidth: false,
}

export default Header
