import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import Header from "./header"
import "@fontsource/inter/400.css"
import "@fontsource/inter/600.css"
import "./normalize.css"
import "./layout.css"

const Layout = ({ children, fullwidth }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let style = {
    margin: `0 auto`,
    padding: `1rem 0.4rem 1.45rem`,
    backgroundColor: "#000",
    color: `#fff`,
  }

  if (fullwidth !== true) {
    style.maxWidth = 960
  }

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        fullwidth={fullwidth}
      />
      <div style={style}>
        <main
          style={{
            minHeight: `70vh`,
          }}
        >
          {children}
        </main>
        <footer
          style={{
            marginTop: `1rem`,
            fontSize: `0.75em`,
            fontStyle: `italic`,
          }}
        >
          ©{new Date().getFullYear()} - write.tf
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Link to="/private-policy">private policy</Link>
          &nbsp;-&nbsp;
          <Link to="/terms-and-conditions">terms &amp; conditions</Link>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  fullwidth: PropTypes.bool,
}

export default Layout
